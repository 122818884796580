import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import "./style.css";

const OnboardingModal = () => {
  const [isOpen, setIsOpen] = useState(
    !Boolean(localStorage.getItem("seenOnboarding"))
  );

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (localStorage.getItem("seenOnboarding") == "false") {
      localStorage.setItem("seenOnboarding", "true");
      setIsOpen(true);
    }
  }, [isOpen]);

  const openSupportLink = () => {
    const phoneNumber = "5561984742620";
    const message = "Estou com dúvidas sobre como usar o sistema Alcon Auto";
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    window.open(url, "_blank");
  };

  return (
    <>
      <div>
        <buton onClick={toggle} className="btn btn-primary float-button">
          Como utilizar?
        </buton>
      </div>

      <Modal isOpen={isOpen} toggle={toggle} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>
          🚀 Bem-vindo ao Alcon Auto! Veja como é fácil de usar
        </ModalHeader>

        <ModalBody>
          <div
            id="onboardingCarousel"
            className="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval="10000"
            wrap="false"
          >
            <div className="carousel-inner">
              <div className="carousel-item active text-right">
                <img
                  src="/onboarding/menus.gif"
                  className="d-block mx-auto"
                  alt="Bem-vindo"
                  width="100%"
                />

                <h5 className="bold mt-3">Menus</h5>
                <p>
                  Para navegar nos menus basta clicar no botão circulado de
                  vermelho como mostra o video acima
                </p>
              </div>

              <div className="carousel-item text-right">
                <img
                  src="/onboarding/config.gif"
                  className="d-block mx-auto"
                  alt="Configurações"
                  width="100%"
                />
                <h5 className="bold mt-3">Configurações</h5>
                <p>
                  No menu Configurações preencha os campos CPF/CNPJ e Endereço e
                  depois clique no botão salvar no final da tela
                </p>
              </div>

              <div className="carousel-item text-right">
                <img
                  src="/onboarding/servicos.gif"
                  className="d-block mx-auto"
                  alt="Criar Serviço"
                  width="100%"
                />
                <h5 className="bold mt-3">Serviços</h5>
                <p>Cadastre seus serviços preenchendo nome e preço</p>
              </div>

              <div className="carousel-item text-right">
                <img
                  src="/onboarding/suporte.gif"
                  className="d-block mx-auto"
                  alt="Suporte"
                  width="100%"
                />
                <h5 className="bold mt-3">Suporte</h5>
                <p>
                  Se ficou alguma dúvida? Nosso suporte está disponível no
                  WhatsApp so chamar!
                </p>

                <button onClick={openSupportLink} className="btn btn-success">
                  Falar no WhatsApp
                </button>
              </div>
            </div>

            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#onboardingCarousel"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Anterior</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#onboardingCarousel"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Próximo</span>
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OnboardingModal;
