import React, { useEffect } from "react";
import Navbar from "../../../Navbar";
import {
  listAllControllServices,
  listAllControllServicesPageNumber,
} from "../../../services/ServiceControllService";
import Sidebar from "../../../Sidebar";
import { CDBSpinner } from "cdbreact";
import { useState } from "react";
import ControleServicoModal from "./modal/ControleServicoModal";
import "./controle-servico.css";
import PaginationBootstrap from "../../../components/PaginationBootstrap";
import { listAllCustomers } from "../../../services/CustomerService";
import { listAllUsers } from "../../../services/UserService";
import { listAllServicos } from "../../../services/ServicoService";
import OrcamentoModal from "./modal/OrcamentoModal";
import CardControlService from "./CardControlService";
import { listAllParts } from "../../../services/PartService";
import { maskPhone } from "../../../util/StringUtils";
import EditarControleServico from "./modal/EditarControleServico";
import { listTodaySchedules } from "../../../services/ScheduleService";
import AgendamentosHojeModal from "./modal/AgendamentosHojeModal";
const dayjs = require("dayjs");

export const ControleServico = () => {
  const [serviceControlls, setServiceControlls] = useState([]);
  const [serviceControllsPageInfo, setServiceControllsPageInfo] = useState({});
  const [customers, setCustomers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [services, setServices] = useState([]);
  const [parts, setParts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [copyServiceControlls, setCopyServiceControlls] = useState([]);
  const [editServiceControll, setEditServiceControll] = useState();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [todaySchedules, setTodaySchedules] = useState([]);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [openControleServicoModal, setOpenControleServicoModal] =
    useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState();

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    handleSidebarToggle();
    listCustomers();
    listControllServices();
    listEmployees();
    listServices();
    loadTodaySchedules();
    //listParts();
  }, []);

  function loadTodaySchedules() {
    listTodaySchedules()
      .then((response) => {
        setTodaySchedules(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function listParts() {
    listAllParts()
      .then((response) => {
        setParts(
          response.map((part) => ({
            value: part.id,
            label: part.name,
            key: part.price,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleEditServiceControll(editServiceControll) {
    setEditServiceControll(editServiceControll);
    setOpenEditModal(true);
  }

  function listControllServices() {
    listAllControllServices()
      .then((response) => {
        setServiceControlls(response.data);
        setCopyServiceControlls(response.data);
        setServiceControllsPageInfo(response.pageInfo);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function listControllServicesPageNumber(pageNumber) {
    setIsLoading(true);
    listAllControllServicesPageNumber(pageNumber)
      .then((response) => {
        setServiceControlls(response.data);
        setServiceControllsPageInfo(response.pageInfo);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function listEmployees() {
    listAllUsers()
      .then((response) => {
        setEmployees(
          response.map((user) => ({
            value: user.id,
            label: user.name,
            key: user.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function listCustomers() {
    listAllCustomers()
      .then((response) => {
        setCustomers(
          response.map((customer) => ({
            value: customer.id,
            label: customer.name + " " + maskPhone(customer.phone.substring(2)),
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function listServices() {
    listAllServicos()
      .then((response) => {
        setServices(
          response.map((service) => ({
            value: service.id,
            label: service.name,
            key: service.price,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleSidebarToggle() {
    const isMobile = window.innerWidth <= 720;
    if (isMobile) {
      setToggled(true);
    }
  }

  const status = ["Concluído", "Criado"];
  function renderLateClass(servico) {
    const restantTime = new Date(servico.estimatedTime) - new Date();
    if (!status.includes(servico.status) && restantTime < 0)
      return "late-class";
  }

  function renderLateTitle(servico) {
    const restantTime = new Date(servico.estimatedTime) - new Date();
    if (!status.includes(servico.status) && restantTime < 0)
      return "Este serviço está com a previsão de entrega atrasada";
  }

  function handleFilterVehicle(e) {
    const serachTerm = e.target.value.toLowerCase();

    if (e.target.value) {
      const filteredControlls = copyServiceControlls.filter(
        (sc) =>
          sc.vehicleDescription.toLowerCase().includes(serachTerm) ||
          sc.car.licensePlate.toLowerCase().includes(serachTerm)
      );
      setServiceControlls(filteredControlls);
    } else {
      setServiceControlls(copyServiceControlls);
    }
  }

  return (
    <div className="d-flex">
      <div>
        <Sidebar toggled={toggled} />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <Navbar toggled={toggled} setToggled={setToggled} />
        <div style={{ height: "100%" }}>
          <div
            style={{
              padding: "20px 5%",
            }}
          >
            <div className="pb-5">
              <div className="mt-3">
                <div className="d-lg-flex justify-content-between">
                  <h3>Controle de Serviços</h3>
                </div>

                {openControleServicoModal && (
                  <ControleServicoModal
                    listControllServices={listControllServices}
                    customers={customers}
                    services={services}
                    employees={employees}
                    parts={parts}
                    listServices={listServices}
                    listCustomers={listCustomers}
                    open={openControleServicoModal}
                    setOpenControleServicoModal={setOpenControleServicoModal}
                    selectedSchedule={selectedSchedule}
                    setSelectedSchedule={setSelectedSchedule}
                    loadTodaySchedules={loadTodaySchedules}
                  />
                )}

                {openEditModal && (
                  <EditarControleServico
                    listControllServices={listControllServices}
                    customers={customers}
                    services={services}
                    employees={employees}
                    serviceControll={editServiceControll}
                    openEditModal={openEditModal}
                    setOpenEditModal={setOpenEditModal}
                  />
                )}

                <div className="d-flex mt-3">
                  <div className="me-2">
                    <button
                      onClick={() => setOpenControleServicoModal(true)}
                      type="button"
                      className="btn no-hover"
                    >
                      <i className="fa fa-plus me-1"></i>Novo
                    </button>
                  </div>

                  <div className="vertical-line"></div>
                  <div className="mx-2">
                    <OrcamentoModal
                      listServices={listServices}
                      services={services}
                    />
                  </div>

                  <div className="w-50 mx-3">
                    <input
                      className="form-control"
                      onChange={handleFilterVehicle}
                      placeholder="Filtrar por nome ou placa do veículo"
                    />
                  </div>
                </div>

                {isLoading ? (
                  <div className="d-flex justify-content-center p-3">
                    <CDBSpinner dark />
                  </div>
                ) : (
                  <>
                    <div className="row">
                      <hr className="mt-2" />
                      {todaySchedules.length > 0 && (
                        <>
                          {openScheduleModal && (
                            <AgendamentosHojeModal
                              open={openScheduleModal}
                              todaySchedules={todaySchedules}
                              setOpenScheduleModal={setOpenScheduleModal}
                              setOpenControleServicoModal={
                                setOpenControleServicoModal
                              }
                              setSelectedSchedule={setSelectedSchedule}
                            />
                          )}

                          <div
                            className="alert p-2 alert-primary bold"
                            role="alert"
                          >
                            Agendamentos de hoje disponíveis para criar Controle
                            de Serviços
                            <button
                              onClick={() => setOpenScheduleModal(true)}
                              className="btn btn-dark mx-3"
                            >
                              Ver
                            </button>
                          </div>
                        </>
                      )}
                      <div className="mb-3">
                        Controle de serviços criados{" "}
                        <strong>
                          {serviceControllsPageInfo
                            ? serviceControllsPageInfo.totalElements
                            : 0}
                        </strong>{" "}
                        Próxima entrega{" "}
                        <strong>
                          {serviceControlls && serviceControlls[0]
                            ? dayjs(serviceControlls[0].estimatedTime).format(
                                "DD/MM HH:mm"
                              )
                            : "N/A"}
                        </strong>{" "}
                        Entrega mais distante{" "}
                        <strong>
                          {serviceControlls && serviceControlls[0]
                            ? dayjs(
                                serviceControlls[serviceControlls.length - 1]
                                  .estimatedTime
                              ).format("DD/MM HH:mm")
                            : "N/A"}
                        </strong>
                      </div>

                      {serviceControlls ? (
                        serviceControlls.map((serviceControll) => (
                          <CardControlService
                            key={serviceControll.id}
                            serviceControll={serviceControll}
                            renderLateClass={renderLateClass}
                            renderLateTitle={renderLateTitle}
                            listControllServices={listControllServices}
                            customers={customers}
                            services={services}
                            employees={employees}
                            parts={parts}
                            handleEditServiceControll={
                              handleEditServiceControll
                            }
                          />
                        ))
                      ) : (
                        <>Nenhum controle de serviço no momento.</>
                      )}
                    </div>

                    <div>
                      {serviceControlls.length > 0 && (
                        <PaginationBootstrap
                          pageInfo={serviceControllsPageInfo}
                          goToPage={listControllServicesPageNumber}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControleServico;
