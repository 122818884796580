import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../../Navbar";
import Sidebar from "../../../Sidebar";
import { Scheduler } from "@aldabil/react-scheduler";
import { ptBR } from "date-fns/locale";
import { TRANSLATIONS } from "../../../consts/agendamento/translations";
import { FIELDS } from "../../../consts/agendamento/fields";
import {
  createSchedule,
  listAllSchedules,
  updateSchedule,
  deleteSchedule,
} from "../../../services/ScheduleService";
import { toast } from "react-toastify";
import { listAllCustomers } from "../../../services/CustomerService";
import { maskPhone } from "../../../util/StringUtils";
import { listAllServicos } from "../../../services/ServicoService";
import currencyBRFormat from "../../../util/CurrencyUtils";
import { findByLicensePlateAndCustomerId } from "../../../services/CarService";

export const Agendamentos = () => {
  const [toggled, setToggled] = useState(false);
  const [customers, setCustomers] = useState();
  const [services, setServices] = useState();
  const [events, setEvents] = useState([]);
  const schedulerRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleSidebarToggle();
    loadServices();
    loadCustomers();
  }, []);

  function loadSchedules(date) {
    schedulerRef.current.scheduler.handleState(true, "loading");

    if (!date) date = new Date();

    listAllSchedules(date.getFullYear(), date.getMonth() + 1)
      .then((response) => {
        setEvents(
          response.map((s) => ({
            ...s,
            start: new Date(s.start),
            end: new Date(s.end),
          }))
        );

        schedulerRef.current.scheduler.handleState(false, "loading");
      })
      .catch((error) =>
        schedulerRef.current.scheduler.handleState(false, "loading")
      );
  }

  function handleSidebarToggle() {
    const isMobile = window.innerWidth <= 720;
    if (isMobile) {
      setToggled(true);
    }
  }

  function loadServices() {
    listAllServicos()
      .then((response) => {
        setServices(
          response.map((service) => ({
            id: service.id,
            text: `${service.name} - ${currencyBRFormat(service.price)}`,
            value: service.id,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function loadCustomers() {
    listAllCustomers()
      .then((response) => {
        setCustomers(
          response.map((customer) => ({
            value: customer.id,
            text: `${customer.name} - ${maskPhone(
              customer.phone.substring(2)
            )}`,
            id: customer.id,
          }))
        );
        loadSchedules();
      })
      .catch((error) => {});
  }

  const handleConfirm = async (event, action) => {
    const saoPauloOffset = -3 * 60; // UTC-3

    const convertToSaoPauloTime = (utcDate) => {
      const date = new Date(utcDate);
      if (isNaN(date.getTime())) {
        console.error("Data inválida:", utcDate);
        throw new Error("Data inválida");
      }
      const saoPauloDate = new Date(
        date.getTime() + saoPauloOffset * 60 * 1000
      );
      return saoPauloDate;
    };

    return new Promise((res, rej) => {
      event.start = convertToSaoPauloTime(event.start);
      event.end = convertToSaoPauloTime(event.end);

     findByLicensePlateAndCustomerId(event.licensePlate, event.customerId)
      .then((response) => {
        if (!response) {
          toast.error("Placa não encontrada ou veículo não possui vinculo com o cliente selecionado, verifique a placa informa e tente novamente");
          return rej("Placa não encontrada ou veículo não possui vinculo com o cliente selecionado, verifique a placa informa e tente novamente");
        } 

        if (action === "edit") {
          updateSchedule(event)
            .then((response) => {
              toast.success("Agendamento atualizado");
              res({ ...event, event_id: event.event_id || Math.random() });
              loadSchedules();
            })
            .catch((error) => {
              toast.error("Não foi possivel atualizar agendamento");
              console.error("handleConfirm: ", error);
              rej("Não foi possivel atualizar agendamento");
            });
        } else if (action === "create") {
          createSchedule(event)
            .then((response) => {
              toast.success("Agendamento realizado");
              res({ ...event, event_id: event.event_id || Math.random() });
              loadSchedules();
            })
            .catch((error) => {
              console.error("handleConfirm: ", error);
              rej("Não foi possivel realizar agendamento");
              toast.error("Não foi possivel realizar agendamento");
            });
        }
      });
    });
  };

  const deleteEvent = async (event_id) => {
    return new Promise((res, rej) => {
      deleteSchedule(event_id)
        .then((response) => {
          toast.success("Agendamento excluído");
          res(event_id);
          //loadSchedules();
        })
        .catch((error) => {
          console.error("deleteEvent: ", error);
          rej("Não foi possivel excluir agendamento");
          toast.error("Não foi possivel excluir agendamento");
        });
    });
  };

  return (
    <div className="d-flex">
      <div>
        <Sidebar toggled={toggled} />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <Navbar toggled={toggled} setToggled={setToggled} />
        <div style={{ height: "100%" }}>
          <div
            style={{
              padding: "20px 5%",
            }}
          >
            <div>
              <div className="mt-3">
                <div className="d-flex justify-content-between">
                  <h3>Agendamentos</h3>
                </div>
              </div>
              <div className="mt-3">
                {customers && services && (
                  <Scheduler
                    ref={schedulerRef}
                    onSelectedDateChange={(date) => loadSchedules(date)}
                    onDelete={deleteEvent}
                    onConfirm={handleConfirm}
                    locale={ptBR}
                    timeZone={"America/Sao_Paulo"}
                    hourFormat="24"
                    view="month"
                    translations={TRANSLATIONS}
                    fields={FIELDS(customers, services)}
                    events={events}
                    draggable={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agendamentos;
