import React, { useEffect, useState } from "react";
import { useRef } from "react";
import {
  createCustomer,
  findCustomerByPhone,
} from "../../../../services/CustomerService";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import removerSpecialChars from "../../../../util/StringUtils";
import ReactInputMask from "react-input-mask";
import carBrands from "../../../../consts/CarBrands";
import ReactSelect from "react-select";
import { createCar } from "../../../../services/CarService";

export const CadastrarClienteVeiculoModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputPhone = useRef("");
  const inputName = useRef("");
  const inputAddress = useRef("");
  const addButton = useRef("");
  const [loading, setLoading] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [notify, setNotify] = useState(true);
  const [vehicleBrand, setVehicleBrand] = useState();
  const inputLicensePlate = useRef("");
  const inputVehicleColor = useRef("");
  const inputVehicleModel = useRef("");
  const [onlyVehicle, setOnlyVehicle] = useState(false);

  useEffect(() => {
    setIsOpen(props.open);
  }, [props]);

  function close() {
    props.setModalCadastroCliente(false);
  }

  function handleSubmitVehicleAndCustomerForm(e) {
    setLoading(true);
    e.preventDefault();

    const data = {
      phone: "55" + removerSpecialChars(inputPhone.current.value),
      name: inputName.current.value,
      address: inputAddress.current.value,
      notify,
      vehicle: {
        vehicleModel: inputVehicleModel.current.value,
        vehicleBrand: vehicleBrand.key,
        licensePlate: inputLicensePlate.current.value,
        vehicleColor: inputVehicleColor.current.value,
      },
    };

    createCustomer(data)
      .then((response) => {
        toast.success("Cliente e Veículo cadastrado");
        props.findVehicle(data.vehicle.licensePlate);
        setLoading(false);
        props.setModalCadastroCliente(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.status)
          toast.error(error.response.data);
        else toast.error("Não foi possivel cadastrar cliente");
      });
  }

  function handleSubmitVehicleForm(e) {
    setLoading(true);
    e.preventDefault();

    const data = {
      vehicleModel: inputVehicleModel.current.value,
      vehicleBrand: vehicleBrand.key,
      licensePlate: inputLicensePlate.current.value,
      vehicleColor: inputVehicleColor.current.value,
    };

    createCar(data)
      .then((response) => {
        toast.success("Veículo cadastrado");
        props.findVehicle(data.licensePlate);
        setLoading(false);
        props.setModalCadastroCliente(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        if (error.response && error.response.status)
          toast.error(error.response.data);
        else toast.error("Não foi possivel cadastrar veículo");
      });
  }

  function findByPhone() {
    findCustomerByPhone("55" + removerSpecialChars(inputPhone.current.value))
      .then((response) => {
        if (response) {
          inputName.current.value = response.name;
          inputAddress.current.value = response.address;
          addButton.current.disabled = true;
          toast.warning(
            "Cliente já cadastrado. Escolha a opção somente veículo no incio deste formulário"
          );
        } else {
          addButton.current.disabled = false;
          inputName.current.value = "";
          inputAddress.current.value = "";
        }
      })
      .catch((error) => {});
  }

  const FormVehicle = () => {
    return (
      <form onSubmit={handleSubmitVehicleForm}>
        <ModalBody>
          <div className="row">
            <div className="col-12 mb-3">
              <label className="bold">Dados do veículo</label>
            </div>

            <div className="col-lg-2">
              <label className="form-label">Placa</label>
              <input
                ref={inputLicensePlate}
                defaultValue={props.licensePlate}
                className="form-control text-uppercase"
                minLength={7}
                maxLength={7}
                required
              />
            </div>
            <div className="col-lg-4 mt-lg-0 mt-3">
              <label className="form-label">Marca</label>
              <ReactSelect
                id="selectBrand"
                name="brands"
                classNamePrefix="select"
                onChange={(brand) => setVehicleBrand(brand)}
                value={vehicleBrand}
                options={carBrands.map((brand, index) => ({
                  value: brand.replace("_", " "),
                  key: brand,
                  label: brand.replace("_", " "),
                }))}
                className="basic-multi-select"
                placeholder=""
                required
              />
            </div>
            <div className="col-lg-3 mt-lg-0 mt-3">
              <label className="form-label">Modelo</label>
              <input
                id="carModel"
                ref={inputVehicleModel}
                type="text"
                className="form-control"
                required
              />
            </div>
            <div className="col-lg-3 mt-lg-0 mt-3">
              <label className="form-label">Cor</label>
              <input
                ref={inputVehicleColor}
                className="form-control"
                placeholder="Opcional"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {!loading ? (
            <>
              <button ref={addButton} type="submit" className="btn btn-dark">
                Adicionar
              </button>

              <button type="button" onClick={close} className="btn btn-danger">
                Cancelar
              </button>
            </>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </ModalFooter>
      </form>
    );
  };

  const FormVehicleAndCustomer = () => {
    return (
      <form onSubmit={handleSubmitVehicleAndCustomerForm}>
        <ModalBody>
          <div className="row mb-3">
            <div className="mb-3">
              <label className="bold">Dados do cliente</label>
            </div>

            <div className="col-lg-3 mb-3">
              <label htmlFor="customerPhone" className="form-label">
                Telefone
              </label>
              <ReactInputMask
                ref={inputPhone}
                type="phone"
                mask="(99) 99999-9999"
                onBlur={findByPhone}
                id="customerPhone"
                className="form-control"
                required
              />
            </div>

            <div className="col-lg-4">
              <label htmlFor="customerName" className="form-label">
                Nome
              </label>
              <input
                ref={inputName}
                type="text"
                id="customerName"
                className="form-control"
                required
              />
            </div>

            <div className="col-lg-4 mt-lg-0 mt-3">
              <label className="form-label bold">Notificar no WhatsApp</label>

              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="switchPaymentMethod"
                  checked={notify}
                  onChange={() => setNotify(!notify)}
                />
                <label className="form-check-label" for="switchPaymentMethod">
                  {notify ? "Sim" : "Não"}
                </label>
              </div>
            </div>

            <div className="col-lg-12 mt-lg-0 mt-3">
              <label htmlFor="address" className="form-label">
                Endereço
              </label>
              <input
                ref={inputAddress}
                type="text"
                id="address"
                className="form-control"
                placeholder="Opcional"
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12 mb-3">
              <label className="bold">Dados do veículo</label>
            </div>

            <div className="col-lg-2">
              <label className="form-label">Placa</label>
              <input
                ref={inputLicensePlate}
                defaultValue={props.licensePlate}
                className="form-control text-uppercase"
                minLength={7}
                maxLength={7}
                required
              />
            </div>
            <div className="col-lg-4 mt-lg-0 mt-3">
              <label className="form-label">Marca</label>
              <ReactSelect
                id="selectBrand"
                name="brands"
                classNamePrefix="select"
                onChange={(brand) => setVehicleBrand(brand)}
                value={vehicleBrand}
                options={carBrands.map((brand, index) => ({
                  value: brand.replace("_", " "),
                  key: brand,
                  label: brand.replace("_", " "),
                }))}
                className="basic-multi-select"
                placeholder=""
                required
              />
            </div>
            <div className="col-lg-3 mt-lg-0 mt-3">
              <label className="form-label">Modelo</label>
              <input
                id="carModel"
                ref={inputVehicleModel}
                type="text"
                className="form-control"
                required
              />
            </div>
            <div className="col-lg-3 mt-lg-0 mt-3">
              <label className="form-label">Cor</label>
              <input
                ref={inputVehicleColor}
                className="form-control"
                placeholder="Opcional"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {!loading ? (
            <>
              <button ref={addButton} type="submit" className="btn btn-dark">
                Adicionar
              </button>

              <button type="button" onClick={close} className="btn btn-danger">
                Cancelar
              </button>
            </>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </ModalFooter>
      </form>
    );
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={close} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>Cadastro de cliente e veículo</ModalHeader>

        <div className="mt-3 mx-3">
          <label className="form-label bold">Tipo de cadastro</label>

          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="radioDefault"
              id="radioDefault"
              onChange={() => setOnlyVehicle(!onlyVehicle)}
              checked={!onlyVehicle}
            />
            <label className="form-check-label" for="radioDefault">
              Cliente e veículo
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="radioDefault2"
              id="radioDefault2"
              onChange={() => setOnlyVehicle(!onlyVehicle)}
              checked={onlyVehicle}
            />
            <label className="form-check-label" for="radioDefault2">
              Somente veículo
            </label>
          </div>
        </div>
        {onlyVehicle ? <FormVehicle /> : <FormVehicleAndCustomer />}
      </Modal>
    </div>
  );
};
export default CadastrarClienteVeiculoModal;
