import React, { useEffect, useState } from "react";
import Navbar from "../../../Navbar";
import Sidebar from "../../../Sidebar";
import AccordionTaxasFormaPagamento from "./AccordionTaxasFormaPagamentot";
import AccordionDadosEmpresa from "./AccordionDadosEmpresa";
import { listPaymentMethodsByCompany } from "../../../services/PaymentMethodService";
import { connect, disconnect, status } from "../../../services/WhatsappService";
import { CDBSpinner } from "cdbreact";
import { getCompanyInfo } from "../../../services/CompanyService";
import { toast } from "react-toastify";
import "./styles.css";
import FidelidadeTab from "./FidelidadeTab";

export const Configuracoes = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [toggled, setToggled] = useState(false);
  const [qrcode, setQrcode] = useState();
  const [whatsAppConnected, setWhatsAppConnected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [companyInfo, setCompanyInfo] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    statusWhatsApp();
    loadCompanyInfo();
    handleSidebarToggle();
    loadPaymentMethods();
  }, []);

  function loadPaymentMethods() {
    listPaymentMethodsByCompany(false)
      .then((response) => {
        setPaymentMethods(response);
      })
      .catch((error) => console.error(error));
  }

  function handleSidebarToggle() {
    const isMobile = window.innerWidth <= 720;
    if (isMobile) {
      setToggled(true);
    }
  }

  function loadCompanyInfo() {
    getCompanyInfo()
      .then((response) => {
        setCompanyInfo(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  function connectWhatsApp() {
    connect()
      .then((response) => {
        console.log(response);
        setQrcode(response.base64);
      })
      .catch((error) => {
        toast.warning(
          "Para conectar o WhatsApp você precisa informar o campo CPF/CNPJ e depois clicar no botão Salvar."
        );
        setQrcode(
          "Informe seu CNPJ nos dados da empresa salve e tente novamente"
        );
      });
  }

  function disconnectWhatsApp(e) {
    e.preventDefault();

    const executeAction = window.confirm(
      "Tem certeza que deseja desconectar seu Whats App?\nMensagens de controle de serviço, marketing e outras não poderão mais ser enviadas."
    );

    if (executeAction) {
      disconnect()
        .then((response) => {
          setQrcode();
          statusWhatsApp();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  function statusWhatsApp() {
    status(false)
      .then((response) => {
        setWhatsAppConnected(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <div className="d-flex">
      <div>
        <Sidebar toggled={toggled} />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <Navbar toggled={toggled} setToggled={setToggled} />
        <div style={{ height: "100%" }}>
          <div
            style={{
              padding: "20px 5%",
            }}
          >
            <div>
              <QRCodeModal qrcode={qrcode} />

              <div className="mt-3">
                <div className="d-lg-flex justify-content-between">
                  <div>
                    <h3>Configurações</h3>
                  </div>
                  <div className="mb-2">
                    {!whatsAppConnected ? (
                      <button
                        className="btn"
                        onClick={() => connectWhatsApp()}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <i className="fa fa-power-off text-success text-success me-1"></i>{" "}
                        Conectar WhatsApp
                      </button>
                    ) : (
                      <button
                        className="btn"
                        onClick={(e) => disconnectWhatsApp(e)}
                      >
                        <i className="fa fa-power-off text-success text-danger me-1"></i>{" "}
                        Desconectar WhatsApp
                      </button>
                    )}
                  </div>
                </div>
                {loading ? (
                  <div className="d-flex justify-content-center p-5">
                    <CDBSpinner dark />
                  </div>
                ) : (
                  <>
                    <div className="mt-3">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            id="tab1"
                            data-bs-toggle="tab"
                            href="#content1"
                          >
                            Empresa
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            id="tab2"
                            data-bs-toggle="tab"
                            href="#content2"
                          >
                            Taxas
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className="nav-link"
                            id="tab2"
                            data-bs-toggle="tab"
                            href="#fidelidadeTab"
                          >
                            Cartão Fidelidade
                          </a>
                        </li>
                      </ul>

                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="content1"
                        >
                          <AccordionDadosEmpresa
                            companyInfo={companyInfo}
                            loadCompanyInfo={loadCompanyInfo}
                          />
                        </div>

                        <div className="tab-pane fade" id="content2">
                          <AccordionTaxasFormaPagamento
                            paymentMethods={paymentMethods}
                            listPayments={loadPaymentMethods}
                          />
                        </div>

                        <div className="tab-pane fade" id="fidelidadeTab">
                          <FidelidadeTab />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const QRCodeModal = (props) => {
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Escaneie este QRCode
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body text-center">
            {props.qrcode ? (
              <img className="w-100" src={props.qrcode} />
            ) : (
              <div className="spinner-border mb-4" role="status" />
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-dark"
              data-bs-dismiss="modal"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Configuracoes;
