import React, { useRef, useState } from "react";
import { updateCompany } from "../../../services/CompanyService";
import { toast } from "react-toastify";
import removerSpecialChars from "../../../util/StringUtils";
import ReactInputMask from "react-input-mask";
import ReactDatePicker from "react-datepicker";

const dayjs = require("dayjs");

export const AccordionDadosEmpresa = (props) => {
  const MAX_FILE_SIZE_BYTES = 200 * 1024;
  const ALLOWED_FILE_TYPES = ["image/png", "image/jpeg", "image/jpg"];
  const inputCnpj = useRef("");
  const inputCorporateName = useRef("");
  const inputFantasyName = useRef("");
  const inputAddress = useRef("");
  const inputEmail = useRef("");
  const inputWhatsAppNumber = useRef("");
  const iputCashCloseDay = useRef("");
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState();
  const [openHour, setOpenHour] = useState(
    new Date(props.companyInfo.openHour)
  );
  const [closeHour, setCloseHour] = useState(
    new Date(props.companyInfo.closeHour)
  );
  const [companyInfo, setCompanyInfo] = useState(props.companyInfo);
  const [remarketingEnabled, setRemarketingEnabled] = useState(
    props.companyInfo.remarketingEnabled
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      // Nenhum arquivo selecionado
      return;
    }

    if (file.size > MAX_FILE_SIZE_BYTES) {
      // O arquivo excede o tamanho máximo permitido
      toast.warning(
        `O tamanho do arquivo deve ser no máximo ${
          MAX_FILE_SIZE_BYTES / 1024
        } KB.`
      );
      // Limpe o input de arquivo ou tome a ação apropriada
      event.target.value = null;
      return;
    }

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      // O formato do arquivo não é suportado
      toast.warning(
        "Formato de arquivo não suportado. Por favor, selecione um arquivo PNG."
      );
      // Limpe o input de arquivo ou tome a ação apropriada
      event.target.value = null;
      return;
    }

    // Faça o que for necessário com o arquivo, se estiver dentro do tamanho e formato permitidos
    setLogo(file);

    if (event.target.files[0] && event.target.files[0].size > 256 * 1024) {
      // O arquivo excede o tamanho máximo permitido
      toast.warning(`O tamanho do arquivo deve ser no máximo 256 kb.`);
      // Limpe o input de arquivo ou tome a ação apropriada
      event.target.value = null;
    } else {
      // Faça o que for necessário com o arquivo, se estiver dentro do tamanho permitido
      setLogo(event.target.files[0]);
    }
  };

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    const data = {
      documentNumber: removerSpecialChars(inputCnpj.current.value),
      corporateName: inputCorporateName.current.value,
      fantasyName: inputFantasyName.current.value,
      whatsAppNumber: removerSpecialChars(inputWhatsAppNumber.current.value),
      address: inputAddress.current.value,
      logo: logo ? logo : null,
      email: inputEmail.current.value,
      openHour: dayjs(openHour).format("HH:mm"),
      closeHour: dayjs(closeHour).format("HH:mm"),
      remarketingEnabled,
      cashCloseDay: parseInt(iputCashCloseDay.current.value),
    };
    const formData = new FormData();

    // Itera sobre as chaves do objeto data e append no FormData
    Object.keys(data).forEach((key) => {
      const value = data[key];

      // Adiciona ao FormData apenas se o valor não for nulo
      if (value !== null) {
        formData.append(key, value);
      }
    });

    updateCompany(companyInfo.id, formData)
      .then((response) => {
        props.loadCompanyInfo(false);
        toast.success(response);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data);
        setLoading(false);
      });
  }

  if (!companyInfo) return null;

  return (
    <div className="card border-top-0">
      <form onSubmit={handleSubmit}>
        <div className="card-body">
          <div className="row p-1">
            <div className="col-lg-6">
              <label className="mb-1 bold">Nome fantasia</label>
              <input
                defaultValue={companyInfo.fantasyName}
                ref={inputFantasyName}
                className="form-control"
                required
              />
            </div>

            <div className="col-lg-6 mt-3 mt-lg-0">
              <label className="mb-1 bold">Razão social</label>
              <input
                defaultValue={companyInfo.corporateName}
                ref={inputCorporateName}
                className="form-control"
                required
              />
            </div>

            <div className="col-lg-6 mt-3 mt-lg-3">
              <label className="mb-1 bold">CPF/CNPJ</label>
              <input
                id="cnpj"
                defaultValue={companyInfo.documentNumber}
                ref={inputCnpj}
                type="text"
                className="form-control"
                min={11}
                max={14}
                maxLength={14}
                minLength={11}
                required
              />
            </div>

            <div className="col-lg-6 mt-3 mt-lg-3">
              <label className="mb-1 bold">Endereço</label>
              <input
                defaultValue={companyInfo.address}
                ref={inputAddress}
                className="form-control"
                required
              />
            </div>
            <div className="col-lg-6 mt-3 mt-lg-3">
              <label className="mb-1 bold">E-mail</label>
              <input
                defaultValue={companyInfo.email}
                ref={inputEmail}
                type="email"
                className="form-control"
              />
            </div>
            <div className="col-lg-6 mt-3 mt-lg-3">
              <label className="mb-1 bold">Telefone</label>
              <ReactInputMask
                defaultValue={companyInfo.whatsAppNumber}
                ref={inputWhatsAppNumber}
                className="form-control"
                mask="(99) 99999-9999"
                required
              />
            </div>
            <div
              className="col-lg-12 mt-3 mt-lg-3"
              title="Dia do mês que ocorre o balanço da empresa e nesse dia é enviado relatorio para seu E-mail"
            >
              <div className="row">
                <div className="col-lg-6">
                  <label className="mb-1 bold">Dia fechamento do caixa</label>

                  <div>
                    <input
                      style={{ width: "5rem" }}
                      ref={iputCashCloseDay}
                      width={1}
                      className="form-control"
                      type="number"
                      defaultValue={companyInfo.cashCloseDay}
                      min={1}
                      max={30}
                      required
                    />
                  </div>
                </div>

                <div
                  className="col-lg-6"
                  title="Habilite se quiser que seja enviado mensagem ao cliente quando o ultimo serviço feito foi há 15 dias"
                >
                  <label className="form-label bold">
                    Enviar lembrete de serviço para os clientes
                  </label>

                  <div className="align-self-center">
                    <div className="form-check form-switch">
                      <input
                        id="switchRemarketing"
                        className="form-check-input"
                        type="checkbox"
                        checked={remarketingEnabled}
                        onChange={() =>
                          setRemarketingEnabled(!remarketingEnabled)
                        }
                      />
                      <label
                        className="form-check-label"
                        for="switchRemarketing"
                      >
                        {remarketingEnabled ? "Sim" : "Não"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-3 mt-lg-3">
              <div>
                <label className="mb-1 bold">Horário de funcionamento</label>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-1">
                    <label>Abre às</label>
                  </div>
                  <ReactDatePicker
                    id="openAt"
                    className="form-control"
                    dateFormat="HH:mm"
                    showTimeSelect
                    showTimeSelectOnly
                    timeCaption="Abre às"
                    locale="pt-BR"
                    selected={openHour}
                    onChange={(date) => setOpenHour(date)}
                    required
                  />
                </div>
                <div className="col-lg-6 mt-lg-0 mt-2">
                  <div className="mb-1">
                    <label>Fecha às</label>
                  </div>

                  <ReactDatePicker
                    id="closeAt"
                    className="form-control"
                    dateFormat="HH:mm"
                    showTimeSelect
                    showTimeSelectOnly
                    locale="pt-BR"
                    timeCaption="Fecha às"
                    selected={closeHour}
                    onChange={(date) => setCloseHour(date)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-3 mt-lg-3">
              <label className="mb-1 bold">Logo</label>

              <input
                onChange={handleFileChange}
                type="file"
                className="form-control"
              />
              {companyInfo.logo && (
                <div className="mt-2">
                  <img
                    width={60}
                    src={"data:image/png;base64," + companyInfo.logo}
                  />
                </div>
              )}
            </div>

            <div className="col-lg-12 mt-3 align-self-end">
              {!loading ? (
                <button type="submit" className="btn btn-dark">
                  Salvar
                </button>
              ) : (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AccordionDadosEmpresa;
