export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const clearCookies = () => {
  const cookies = document.cookie.split(";");
  console.log("clearCookies -> ", cookies);
  cookies.forEach((cookie) => {

    const cookieName = cookie.split("=")[0].trim();
    document.cookie = `${cookieName}=; path=/; domain=.alconauto.com.br; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
    document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
  });
};
