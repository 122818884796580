import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../../Navbar";
import Sidebar from "../../../Sidebar";
import { CDBTable, CDBTableHeader, CDBTableBody, CDBSpinner } from "cdbreact";
import {
  findAllCarsByCompany,
  findAllCarsByCompanyPage,
  deleteCar,
  findCarLicensePlateLikeOrModel,
} from "../../../services/CarService";
import { toast } from "react-toastify";
import PaginationBootstrap from "../../../components/PaginationBootstrap";
import EditarVeiculo from "./EditarVeiculo";
import NovoVeiculo from "./NovoVeiculo";

export const Veiculos = () => {
  const [vehicles, setVehicles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState();
  const [toggled, setToggled] = useState(false);
  const filterLicensePlate = useRef("");

  useEffect(() => {
    window.scrollTo(0, 0);
    handleSidebarToggle();
    setIsLoading(true);
    listVehicles();
  }, []);

  function removeVehicle(id) {
    const execute = window.confirm(
      "Tem certeza que deseja excluir este veículo?"
    );

    if (execute) {
      deleteCar(id)
        .then((response) => {
          toast.success(response);
          listVehicles();
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status == 422)
            toast.warn(error.response.data);
          else toast.error("Não foi possivel exluir este veículo");
        });
    }
  }

  function listVehicles() {
    findAllCarsByCompany()
      .then((response) => {
        setVehicles(response.data);
        setPageInfo(response.pageInfo);
        setIsLoading(false);
      })
      .catch((error) => setIsLoading(false));
  }

  function listVehiclesByPage(page) {
    findAllCarsByCompanyPage(page)
      .then((response) => {
        setVehicles(response.data);
        setPageInfo(response.pageInfo);
        setIsLoading(false);
      })
      .catch((error) => setIsLoading(false));
  }

  function filterCarLicensePlateLikeOrModel() {
    if (filterLicensePlate.current.value) {
      setIsLoading(true);
      findCarLicensePlateLikeOrModel(filterLicensePlate.current.value)
        .then((response) => {
          setVehicles(response.data);
          setPageInfo(response.pageInfo);
          setIsLoading(false);
        })
        .catch((error) => setIsLoading(false));
    }
  }

  function handleSidebarToggle() {
    const isMobile = window.innerWidth <= 720;
    if (isMobile) {
      setToggled(true);
    }
  }

  function handleClickClearFilter() {
    listVehicles();
    filterLicensePlate.current.value = "";
  }

  return (
    <div className="d-flex">
      <div>
        <Sidebar toggled={toggled} />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <Navbar toggled={toggled} setToggled={setToggled} />
        <div>
          <div
            style={{
              padding: "20px 5%",
            }}
          >
            <div>
              <div className="mt-3">
                <div className="d-flex justify-content-between">
                  <h3 className="font-weight-bold mb-3">Veículos</h3>
                </div>

                <div className="d-flex mt-3 mb-1">
                  <NovoVeiculo listVehicles={listVehicles} />

                  <div>
                    <button
                      className="btn"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <i className="fa fa-filter me-2"></i>
                      Filtrar
                    </button>
                  </div>
                </div>

                <div className="mt-3 mb-3">
                  <div className="collapse" id="collapseExample">
                    <div className="card card-body">
                      <div>
                        <input
                          ref={filterLicensePlate}
                          className="form-control"
                          placeholder="Filtrar por placa ou modelo do veículo"
                        />
                      </div>
                      <div className="mt-3">
                        <button
                          className="btn btn-sm btn-dark"
                          onClick={filterCarLicensePlateLikeOrModel}
                          disabled={!filterLicensePlate}
                        >
                          <i
                            className="fa fa-search me-2"
                            aria-hidden="true"
                          ></i>
                          Pesquisar
                        </button>

                        <button
                          className="btn mx-3"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseExample"
                          onClick={handleClickClearFilter}
                        >
                          <i className="fa fa-times me-2"></i>
                          Limpar filtro
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {isLoading ? (
                  <div className="d-flex justify-content-center p-3">
                    <CDBSpinner dark />
                  </div>
                ) : (
                  <>
                    <CDBTable responsiveSm responsiveMd>
                      <CDBTableHeader>
                        <tr>
                          <th>Placa</th>
                          <th>Fabricante</th>
                          <th>Modelo</th>
                          <th>Cor</th>
                          <th>Cliente</th>
                          <th>Ações</th>
                        </tr>
                      </CDBTableHeader>
                      <CDBTableBody>
                        {vehicles ? (
                          vehicles.map((car) => (
                            <tr key={car.id}>
                              <td>{car.licensePlate}</td>
                              <td>{car.brand.replace("_", " ")}</td>
                              <td>{car.model}</td>
                              <td>{car.color}</td>
                              <td>{car.customerName}</td>
                              <td>
                                <div className="d-flex">
                                  <div>
                                    <EditarVeiculo
                                      listVehicles={listVehicles}
                                      vehicle={car}
                                    />
                                  </div>

                                  <div className="">
                                    <button
                                      className="btn btn-danger mx-3"
                                      type="button"
                                      onClick={() => removeVehicle(car.id)}
                                    >
                                      <i className="fa fa-trash" />
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <></>
                        )}
                      </CDBTableBody>
                    </CDBTable>
                    <div>
                      {vehicles.length > 0 ? (
                        <PaginationBootstrap
                          pageInfo={pageInfo}
                          goToPage={listVehiclesByPage}
                        />
                      ) : (
                        <>Nenhum veículo cadastrado.</>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Veiculos;
