import { CDBTable, CDBTableBody, CDBTableHeader } from "cdbreact";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import { Modal, ModalBody, ModalHeader } from "reactstrap";

export const AgendamentosHojeModal = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsOpen(props.open);
  }, [props]);

  function close() {
    props.setOpenScheduleModal(false);
  }

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();
  }

  function handleClickCreate(schedule) {
    props.setOpenControleServicoModal(true);
    props.setSelectedSchedule(schedule)
    close();
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={close} backdrop="static" size="lg">
        <ModalHeader toggle={close}>Agendamentos de hoje</ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <CDBTable responsiveSm responsiveMd>
              <CDBTableHeader>
                <tr>
                  <th>Horário</th>
                  <th>Título</th>
                  <th>Cliente</th>
                  <th>Serviços</th>
                  <th width="20%">Ações</th>
                </tr>
              </CDBTableHeader>
              <CDBTableBody>
                {props &&
                  props.todaySchedules.map((s) => (
                    <tr key={s.event_id}>
                      <td>{dayjs(s.start).format("HH:mm")}</td>
                      <td>{s.title}</td>
                      <td>{s.customerName}</td>
                      <td>{s.servicesNames.map((name) => name).join(", ")}</td>
                      <td>
                        <button
                          className="btn btn-dark"
                          onClick={() => handleClickCreate(s)}
                        >
                          Criar Controle
                        </button>
                      </td>
                    </tr>
                  ))}
              </CDBTableBody>
            </CDBTable>
          </ModalBody>
        </form>
      </Modal>
    </div>
  );
};
export default AgendamentosHojeModal;
