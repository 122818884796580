import axiosInstance from "../interceptors/axiosInterceptor";
import getAuthHeaders from "../util/HeadersUtil";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export async function listTodaySchedules() {
  const serviceControllUrl = `${API_URL}/schedules/today`;
  const response = await axiosInstance.get(serviceControllUrl, {
    headers: getAuthHeaders(),
  });

  return response.data;
}

export async function listAllSchedules(year, month) {
  const serviceControllUrl = `${API_URL}/schedules?year=${year}&month=${month}`;
  const response = await axiosInstance.get(serviceControllUrl, {
    headers: getAuthHeaders(),
  });

  return response.data;
}

export async function createSchedule(data) {
  const serviceControllUrl = `${API_URL}/schedules`;
  const response = await axiosInstance.post(serviceControllUrl, data, {
    headers: getAuthHeaders(),
  });

  return response.data;
}

export async function updateSchedule(data) {
  const serviceControllUrl = `${API_URL}/schedules/${data.event_id}`;
  const response = await axiosInstance.put(serviceControllUrl, data, {
    headers: getAuthHeaders(),
  });

  return response.data;
}

export async function deleteSchedule(event_id) {
  const serviceControllUrl = `${API_URL}/schedules/${event_id}`;
  const response = await axiosInstance.delete(serviceControllUrl, {
    headers: getAuthHeaders(),
  });

  return response.data;
}
