export const FIELDS = (customers, services) => [
  {
    name: "customerId",
    type: "select",
    options: customers,
    config: {
      label: "Cliente",
      required: true,
      searchable: true,
    },
  },
  {
    name: "licensePlate",
    type: "input",
    config: {
      label: "Placa Veículo",
      required: true,
      max: 7,
      maxLength: 7,
      mask: "000-000",
      ClassName: "text-uppercase",
      className: "text-uppercase",
      labelClassName: "text-uppercase",
    },
  },
  {
    name: "servicesIds",
    type: "select",
    options: services,
    config: {
      label: "Serviços",
      multiple: "chips",
      required: true,
      searchable: true,
    },
  },
];
