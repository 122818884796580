import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../../Navbar";
import Sidebar from "../../../Sidebar";
import { CDBTable, CDBTableHeader, CDBTableBody, CDBSpinner } from "cdbreact";
import AdicionarDespesaModal from "./AdicionarDespesaModal";
import {
  deleteExpenseById,
  listAllExpensesByPage,
} from "../../../services/ExpenseService";
import currencyBRFormat from "../../../util/CurrencyUtils";
import EditarDespesaModal from "./EditarDespesa";
import { toast } from "react-toastify";
import PaginationBootstrap from "../../../components/PaginationBootstrap";
import ReactDatePicker from "react-datepicker";
import isAuthorized from "../../../util/RoleUtil";
const dayjs = require("dayjs");

export const Despesas = () => {
  const [expenses, setExpenses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState();
  const [toggled, setToggled] = useState(false);
  const date = new Date();
  const defaultStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(
    new Date(
      defaultStartDate.getFullYear(),
      defaultStartDate.getMonth(),
      dayjs(defaultStartDate).daysInMonth()
    )
  );
  const bottomRef = useRef(null);
  let isMobile = window.innerWidth <= 720;

  useEffect(() => {
    window.scrollTo(0, 0);
    handleSidebarToggle();
    listExpenses();
  }, []);

  function handleSearchFilters() {
    listExpenses();
    scrollToBottom();
  }

  function handleUpdatePaidExpensa() {
    const action = window.confirm(
      `Tem certeza que deseja atualizar todas as contas de ${dayjs(
        startDate
      ).format("DD/MM/YYYY")} até ${dayjs(startDate).format(
        "DD/MM/YYYY"
      )} para pagas?`
    );
  }

  function handleClearFilters() {
    setStartDate(new Date());
    setEndDate(new Date());
    listExpenses();
  }

  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  function listExpenses() {
    setIsLoading(true);
    const filter = {
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
      endDate: dayjs(endDate).format("YYYY-MM-DD"),
    };
    listAllExpensesByPage(null, filter)
      .then((response) => {
        setExpenses(response.data);
        setPageInfo(response.pageInfo);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("[despesas] listExpenses", error);
      });
  }

  function listExpensesByPage(page) {
    setIsLoading(true);
    const filter = {
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
      endDate: dayjs(endDate).format("YYYY-MM-DD"),
    };
    listAllExpensesByPage(page, filter)
      .then((response) => {
        const data = response.data;
        setExpenses(data);
        setPageInfo(response.pageInfo);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("[despesas] listExpensesByPage", error);
      });
  }

  function deleteExpense(expense) {
    const executeAction = window.confirm(
      `Confirma a exclusão irreversível da despesa\n ${expense.description}?`
    );

    if (executeAction) {
      deleteExpenseById(expense.id)
        .then((response) => {
          toast.success(response);
          listExpenses(false);
        })
        .catch((error) => toast.error(error));
    }
  }

  function handleSidebarToggle() {
    if (isMobile) {
      setToggled(true);
    }
  }

  return (
    <div className="d-flex">
      <div>
        <Sidebar toggled={toggled} />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <Navbar toggled={toggled} setToggled={setToggled} />
        <div>
          <div
            style={{
              padding: "20px 5%",
            }}
          >
            <div>
              <div className="mt-3 w-100 d-flex">
                <h3>Despesas</h3>
              </div>

              <>
                <div className="d-flex mt-3 mb-3">
                  <div>
                    <AdicionarDespesaModal listExpenses={listExpenses} />
                  </div>

                  <div className="vertical-line"></div>

                  <div>
                    <button
                      className="btn"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <i className="fa fa-filter me-2"></i>
                      Filtrar
                    </button>
                  </div>
                </div>

                <div className="mt-3 mb-3">
                  <div className="collapse" id="collapseExample">
                    <div className="card card-body">
                      <div>
                        <div className="row">
                          <div className="col-lg-2">
                            <div className="mb-2">
                              <label>Data inicial</label>
                            </div>
                            <ReactDatePicker
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              locale="pt-BR"
                              selected={startDate}
                              todayButton="Hoje"
                              onMonthChange={(data) => setStartDate(data)}
                              onChange={(date) => setStartDate(date)}
                            />
                          </div>

                          <div className="col-lg-2">
                            <div className="mb-2 mt-lg-0 mt-3">
                              <label>Data final</label>
                            </div>

                            <ReactDatePicker
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              locale="pt-BR"
                              selected={endDate}
                              todayButton="Hoje"
                              onMonthChange={(data) => setEndDate(data)}
                              onChange={(date) => setEndDate(date)}
                            />
                          </div>

                          <div className="mt-3">
                            <button
                              onClick={handleSearchFilters}
                              className="btn btn-sm btn-dark"
                              disabled={!startDate || !endDate}
                            >
                              {" "}
                              <i
                                className="fa fa-search me-2"
                                aria-hidden="true"
                              ></i>
                              Pesquisar
                            </button>
                            <button
                              onClick={handleUpdatePaidExpensa}
                              className="d-none btn btn-sm btn-dark mx-3"
                              disabled={!startDate || !endDate}
                              title="Esse botão atualiza todas as despesas do período filtrado para pagas"
                            >
                              <i
                                className="fa fa-check me-2"
                                aria-hidden="true"
                              ></i>
                              Atualizar pagas
                            </button>
                            <button
                              onClick={handleClearFilters}
                              className="d-none btn mt-lg-0 mt-md-0 mt-3 mx-2"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseExample"
                            >
                              <i className="fa fa-times me-2"></i>
                              Limpar filtros
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>

              {isLoading ? (
                <div className="d-flex justify-content-center p-3">
                  <CDBSpinner dark />
                </div>
              ) : (
                <>
                  <CDBTable responsiveSm responsiveMd>
                    <CDBTableHeader>
                      <tr>
                        <th>Data</th>
                        <th>Descrição</th>
                        <th>Tipo</th>
                        <th>Valor</th>
                        <th>Paga</th>
                        <th>Lembrete vencimento</th>
                        <th>Ações</th>
                      </tr>
                    </CDBTableHeader>
                    <CDBTableBody>
                      {expenses ? (
                        expenses.map((expense) => (
                          <tr key={expense.id}>
                            <td>
                              {dayjs(expense.createdAt).format("DD/MM/YYYY")}
                            </td>
                            <td>{expense.description}</td>
                            <td className="bold">{expense.type}</td>
                            <td>{currencyBRFormat(expense.value)}</td>
                            <td>{expense.paid == true ? "Sim" : "Não"}</td>
                            <td>
                              {expense.notifyDueDate == true ? "Sim" : "Não"}
                            </td>
                            <td>
                              <div className="d-flex">
                                {isAuthorized(["ROLE_OWNER"]) && (
                                  <>
                                    <div>
                                      <EditarDespesaModal
                                        expense={expense}
                                        listExpenses={listExpenses}
                                      />
                                    </div>
                                    <div>
                                      <button
                                        className="btn btn-danger mx-3"
                                        onClick={() => deleteExpense(expense)}
                                      >
                                        <i className="fa fa-trash" />
                                      </button>
                                    </div>
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                    </CDBTableBody>
                  </CDBTable>
                  <div ref={bottomRef}>
                    {expenses.length > 0 ? (
                      <div>
                        <div className="d-flex justify-content-end">
                          <label>Total em despesas</label>
                          <div className="mx-2 ">
                            {expenses &&
                              currencyBRFormat(
                                expenses
                                  .map((exp) => exp.value)
                                  .reduce(
                                    (a, b) => parseFloat(a) + parseFloat(b),
                                    0
                                  )
                              )}
                          </div>
                        </div>
                        <PaginationBootstrap
                          pageInfo={pageInfo}
                          goToPage={listExpensesByPage}
                        />
                      </div>
                    ) : (
                      <>Nenhuma despesa encontrada nesse período</>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Despesas;
